import React from 'react'
import { FiX } from 'react-icons/fi'
import { useKeyboardEvent, DisableBodyScroll } from '../../utils'
import Portal from './Portal'
import PopupTitle from './PopupTitle'

export default function Popup({ children, close, title, className, small = false }) {
  useKeyboardEvent("Escape", () => close())
  return (
    <>
      <DisableBodyScroll />
      <Portal>
        <div
          className={`fixed flex justify-center items-center h-screen w-screen top-0 left-0 z-40 cursor-default text-black z-75 ${className}`}>
          <div
            onClick={close}
            className="fixed h-screen w-screen top-0 left-0 bg-black bg-opacity-25" />
          <div className={`relative bg-white w-full ${small ? 'lg:max-w-200' : 'lg:max-w-250'} mx-auto h-auto max-h-full lg:max-h-4/5 overflow-y-auto p-6 rounded shadow`}>
            {close && 
              <FiX
                onClick={close}
                className="absolute top-0 right-0 m-2 lg:m-4 w-8 h-8 cursor-pointer stroke-1 z-40" />
            }
            {title && <PopupTitle className="text-center -mt-3 lg:-mt-2">{title}</PopupTitle>}
            <div className="flex flex-col justify-between items-center flex-wrap text-center min-h-full">
              {children}
            </div>
          </div>
        </div>
      </Portal>
    </>
  )
}
