import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Popup from './UI/Popup'
import { language } from '../utils'

export default function HelpCenter({ className, showHelp: show, setShowHelp: setShow }) {
    const { strapi } = useStaticQuery(graphql`
        {
        strapi {
            helps {
            answer
            title
            locale {
                language
            }
            }
        }
        }
    `)

    return (
        <React.Fragment>
            <div 
                className="flex flex-col justify-center items-center w-10/12 sm:w-100 lg:w-64 h-40 lg:h-36 shadow-lg mb-6 lg:mb-0 cursor-pointer rounded-xl text-white bg-pink"
                onClick={() => setShow(true)}>
                <p className="text-3xl">❔❕</p>
                <p className="text-3xl">FAQ</p>
            </div>
            {show &&
                <Popup title="Q&#38;A" close={() => setShow(false)}>
                <div className="text-left">
                    {strapi.helps.filter(h => h.locale?.language === language()).map(({ answer, title }) => (
                    <div className="my-4">
                        <div className="font-bold">{title}</div>
                        <ReactMarkdown className="font-thin">{answer}</ReactMarkdown>
                    </div>
                    ))}
                </div>
                </Popup>
            }
        </React.Fragment>
    )
}
